<template>
  <Layout>
    <div class="paymentMethod">
      <div v-if="loading" class="data-loading">
        <van-loading type="spinner" color="#ff9933"/>
      </div>
      <div v-else>
        <div v-if="loginStatus">
          <div class="header">
            <h1>₹ {{ paymentData.payAmount }}</h1>
          </div>
          <div class="section">
            <h4>Select an option to pay:</h4>
            <div
                class="detail"
                v-for="item in payTypeList"
                :key="item.code"
                @click="selectPay(item)"
            >
              <img :src="require(`../assets/${item.icon}.png`)"/>
              <div>
                <h4>{{ item.title }}</h4>
                <p>{{ item.subTitle }}</p>
              </div>
              <img
                  class="select"
                  :class="{ selectUpi: true }"
                  :src="
                require(`../assets/${
                  selectItem.code !== item.code ? 'un' : ''
                }select.png`)
              "
              />
            </div>
            <div class="bottom">
              <button
                  :class="{ opacity: selectItem.code }"
                  v-if="paymentData.payAmount"
                  @click="next"
              >
                PAY
              </button>
              <button class="opacity" v-else @click="close">
                Close
              </button>
            </div>
          </div>
        </div>
        <div v-else class="unauthorized">
          <p class="content">Expired/Unauthorized</p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/layout/index.vue";
import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import {getQuery} from "@/utils/utils";
import {paymentValidate} from "@/api/payment";

const store = useStore();
const router = useRouter();

// 登录状态
let loginStatus = ref(true);

// 加载....
let loading = ref(true);

// 选中支付方式
let selectItem = reactive({});

// 可选支付列表
let payTypeList = ref([
  {
    code: "upiCollect",
    title: "UPI Collect",
    subTitle: "Pay using UPI",
    icon: "UPI",
    option: "UPI_COLLECT",
  },
  {
    code: "upi",
    title: "UPI",
    subTitle: "Google Pay, Phone Pay & more",
    icon: "UPI",
    option: "UPI_INTENT",
  },
  {
    code: "qrCode",
    title: "QR CODE",
    subTitle: "Scan the QR code using your UPI app",
    icon: "QRCode",
    option: "QR_CODE",
  },
  {
    code: "pagePay",
    title: "Pay through Bank",
    subTitle: "Credit/Debit Card/Net banking",
    icon: "PagePay",
    option: "BANK_PG_PAGE",
  },
]);

// 初始数据
let paymentData = reactive({
  sessionId: "",
  payAmount: "0",
  merchantName: "",
  redirectLink: "",
  paymentComponents: [],
});

// 点击支付方式
const selectPay = (item) => {
  selectItem.code = item.code;
};

// 下一步
const next = () => {
  // 拉起手机
  router.replace({
    path: `/${selectItem.code}`,
    query: {
      amount: paymentData.payAmount,
    },
  });
};

//关闭窗口
const close = () => {
  window.location.href = "about:blank";
  window.close();
};

//登录获取初始化数据
onMounted(() => login());

// 获取交易数据
let login = async () => {
  const response = await paymentValidate({onetimeId: getQuery("onetimeId")});
  if (200 === response.code) {
    store.commit("updateMchName", response.data.merchantName);
    store.commit("updateMchLogo", response.data.iconUrl);
    paymentData.payAmount = response.data.payAmount;
    paymentData = response.data;

    sessionStorage.setItem("sessionId", paymentData.sessionId);
    if (paymentData.redirectLink) {
      sessionStorage.setItem("redirectLink", paymentData.redirectLink);
    }

    //过滤 接口返回接口数据 支付方式是否跟我们定义一致
    payTypeList.value = payTypeList.value.filter((item) =>
        paymentData.paymentComponents.some(
            (pay) => pay.paymentOption === item.option
        )
    );
  } else {
    loginStatus.value = false;
  }
  loading.value = false;
};
</script>

<style scoped lang="scss" type="text/scss">
.paymentMethod {
  width: 100%;
  height: 100%;

  .header {
    text-align: center;
    overflow: hidden;

    h1 {
      margin: 0 0 6%;
      color: #333333;
    }

    p {
      color: #666666;
      margin: 2% 0 4%;
    }
  }

  .data-loading {
    text-align: center;
    padding: 50% 0;
  }

  .unauthorized {
    text-align: center;
    .content {
      font-weight: bold;
      font-size: 1.5rem;
      padding: 50% 10%;
    }
  }

  .section {
    color: #333333;

    .detail {
      margin: 4% 0;
      padding: 3%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: solid 1px #ececec;
      overflow: hidden;
      position: relative;

      img {
        width: 10%;
        margin: 0 4% 0 0;
        float: left;
      }

      div {
        float: left;

        h4 {
          font-size: 0.9rem;
          margin-bottom: 2%;
        }

        p {
          color: #666666;
          font-size: 0.7rem;
        }
      }

      .select {
        width: 7%;
        position: absolute;
        top: 30%;
        right: 0;
      }

      .selectUpi {
        top: 16%;
      }

      input {
        width: 100%;
        margin-top: 4%;
        padding: 4%;
        border-radius: 8px;
        border: solid 1px #d9d9d9;
        outline: none;
        background: #ffffff;
      }
    }
  }

  .bottom {
    width: 92%;
    position: absolute;
    bottom: 5%;
    color: #999999;
    font-size: 1.2rem;
    height: 50px;
    line-height: 50px;

    button {
      float: right;
      width: 100%;
      height: 50px;
      border: none;
      outline: none;
      color: #ffffff;
      background-color: #ff9933;
      border-radius: 48px;
      opacity: 0.3;
    }
  }
}

.opacity {
  opacity: 1 !important;
}
</style>
