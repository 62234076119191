import {createStore} from 'vuex'

export default createStore({
    state: {
        merchant: {
            name: 'MPurse Services Pvt.ltd.',
            logo: ''
        }
    },
    mutations: {
        updateMchName(state, merchantName) {
            state.merchant.name = merchantName
        },
        updateMchLogo(state, iconUrl) {
            state.merchant.logo = iconUrl
        },
    },
    actions: {},
    modules: {}
})