<template>
  <router-view></router-view>
</template>

<style lang="scss" type="text/scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 100%;
}

.text {
  color: #333;
  font-size: 16px;
}
</style>