import axios from "axios";
import { Toast } from 'vant';

const sessionKey = "SESSION-ID";

// 创建 axios 实例
const service = axios.create({
  timeout: 50000,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers[sessionKey] = sessionStorage.getItem("sessionId");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === 200 || !res.code) {
      return res;
    } else {
      Toast(res.message);
      return res;
    }
  },
  (error) => {
    console.log("err" + error);
    Toast(error.message || "请求失败，请联系管理员");
    return Promise.resolve(error);
  }
);

// 导出 axios 实例
export default service;
