import request from "@/utils/request";

//校验此onetimeId是否有效，并返回有效数据
export function paymentValidate(data) {
  return request({
    url: "/h5/v1/gateway/validate",
    method: "post",
    params: data,
  });
}

//UPI Intent 支付
export function upiIntentPayment(data, sessionId) {
  return request({
    url: "/h5/v1/gateway/upi/intent",
    method: "post",
    params: data,
  });
}

// vpa验证
export function upiVpaCheck(data, sessionId) {
  return request({
    url: "/h5/v1/gateway/upi/vpaCheck",
    method: "post",
    params: data,
  });
}

// 发起 UPI Collect 支付
export function upiCollectPayment(data, sessionId) {
  return request({
    url: "/h5/v1/gateway/upi/collect",
    method: "post",
    params: data,
  });
}

//查询UPI支付结果
export function getPaymentResult(data, sessionId) {
  return request({
    url: "/h5/v1/gateway/payment/result",
    method: "post",
    params: data,
  });
}

//H5 PagePay 支付
export function pagePayPayment(sessionId) {
  return request({
    url: "/h5/v1/gateway/page/dynamic",
    method: "post",
  });
}
