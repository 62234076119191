<template>
  <div id="nav">
    <p id="title">
      <span>{{ route.matched[0].meta.title }}</span>
      <img src="../assets/close.png" alt="" @click="showPopup" />
    </p>
    <div id="logo">
      <img
        v-if="store.state.merchant.logo"
        :src="store.state.merchant.logo"
        alt="logo"
      />
      <p v-else class="no-logo">
        {{ store.state.merchant.name }}
      </p>
    </div>
  </div>
  <div id="section">
    <div class="payment">
      <slot></slot>
    </div>
  </div>
  <van-popup v-model:show="show" round>
    <h2>{{ props.popInfo.title }}</h2>
    <p>
      {{ props.popInfo.content }}
    </p>
    <div>
      <button @click="quitBtn">Yes, cancel</button>
      <button @click="closeBtn">No</button>
    </div>
  </van-popup>
</template>

<script setup>
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {ref} from "vue";

const store = useStore();
const show = ref(false);
const route = useRoute();

const props = defineProps({
  popInfo: {
    type: Object,
    required: false,
    default: () => {
      return {
        title: "CANCEL PAYMENT?",
        content:
          "Your payment is ongoing. Are you sure you want to cancel the payment?",
      };
    },
  },
});

const showPopup = () => {
  show.value = true;
};

//close pop
const closeBtn = () => {
  show.value = false;
};

const quitBtn = () => {
  window.location.href = sessionStorage.getItem("redirectLink");
  window.close();
};
</script>

<style lang="scss" type="text/scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background: #f5f5f5;
}

@media screen and (max-width: 480px) {
  #app {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 480px) {
  body {
    padding: 10% 0;
  }

  #app {
    width: 375px;
    height: 667px;
    margin: 0 auto;
  }
}

#nav {
  height: 25%;
  min-height: 220px;
  background-color: #ff9933;
  position: relative;
}

#title {
  padding: 4%;
  text-align: center;
  font-size: 1.5rem;
  color: #ffffff;
  position: relative;

  img:first-child {
    width: 5%;
    position: absolute;
    left: 4%;
    top: 1.2rem;
  }

  img:last-child {
    width: 5%;
    position: absolute;
    right: 4%;
    top: 1.2rem;
  }
}

#logo {
  position: absolute;
  left: 4%;
  bottom: 0;
  width: 92%;
  height: 65%;
  padding: 5% 0;
  text-align: center;
  background: #ffffff;
  border-radius: 6px 6px 0 0;

  img {
    width: 30%;
    margin-bottom: 2%;
  }

  p {
    color: #333333;
    font-size: 1.4rem;
    font-weight: bold;
  }
  .no-logo {
    font-size: 2.1rem;
    margin-top: 10%;
  }
}

#section {
  width: 100%;
  height: 75%;
  min-height: 450px;

  .payment {
    width: 92%;
    height: 95%;
    margin: 0 4%;
    padding: 0 4%;
    background: #ffffff;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.04);
    border-radius: 0 0 6px 6px;
    position: relative;
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999999;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999999;
}

.van-popup {
  width: 80%;
  padding: 6%;
  text-align: center;

  h2 {
    margin-bottom: 5%;
    color: #333333;
  }

  p {
    color: #666666;
    line-height: 1.5rem;
    margin-bottom: 5%;
  }

  button {
    width: 40%;
    height: 50px;
    border: none;
    outline: none;
    color: #ffffff;
    background: #ff9933;
    border-radius: 48px;
  }

  button:first-child {
    float: left;
    background: #cccbd1;
    opacity: 0.5;
  }

  button:last-child {
    float: right;
  }
}
.van-popup.loading {
  width: 25% !important;
}
.van-count-down {
  font-size: 1.3rem;
  font-weight: bold;
}
</style>
