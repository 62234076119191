import {createRouter, createWebHistory} from "vue-router";
import PaymentMethod from "../views/PaymentMethod.vue";

const routes = [
    {
        path: "/",
        name: "PaymentMethod",
        component: PaymentMethod,
        meta: {title: "Payment Method"},
    },
    {
        path: "/qrCode",
        name: "QRCode",
        component: () => import("@/views/QRCode.vue"),
        meta: {title: "QR Payment"},
    },
    {
        path: "/upi",
        name: "UPI",
        component: () => import("@/views/UPI.vue"),
        meta: {title: "UPI Payment"},
    },
    {
        path: "/upiCollect",
        name: "UPICollect",
        component: () => import("@/views/UPICollect.vue"),
        meta: {title: "UPI Collect"},
    },
    {
        path: "/upiCountdown",
        name: "UPICountdown",
        component: () => import("@/views/UPICountdown.vue"),
        meta: {title: "UPI Collect"},
    },
    {
        path: "/pagePay",
        name: "pagePay",
        component: () => import("@/views/H5PagePay.vue"),
        meta: {title: "Net Banking"},
    },
    {
        path: "/paymentResult",
        name: "PaymentResult",
        component: () => import("@/views/PaymentResult.vue"),
        meta: {title: "Payment Result"},
    },
    {
        path: "/getUUID",
        name: "UUID",
        component: () => import("@/views/getUUID.vue"),
        meta: {title: "UUID"},
    },
];

const router = createRouter({
    history: createWebHistory(process.env.PUBLIC_PATH || '/'),
    routes,
});

export default router;
