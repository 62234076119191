import {CountDown, Dialog, Field, Loading, Popup, PullRefresh, Step, Steps, Toast,} from "vant";
import {getQuery} from "./utils/utils";
import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const onetimeId = getQuery('onetimeId');
if (onetimeId) {
    let onetimeIdStorage = sessionStorage.getItem("onetimeId");
    if (onetimeIdStorage && onetimeIdStorage !== onetimeId) {
        sessionStorage.clear();
    }
    sessionStorage.setItem("onetimeId", onetimeId);
    document.getElementById("expire").style.display = "none";
    document.getElementById("app").style.visibility = "visible";

    const vm = createApp(App);
    [
        Popup,
        Toast,
        PullRefresh,
        Loading,
        Field,
        Step,
        Steps,
        CountDown,
        Dialog,
    ].map((comp) => vm.use(comp));

    vm.use(store)
        .use(router)
        .mount("#app");
} else {
    exit();
}

// 关闭当前页面
function exit() {
    document.getElementById("expire").innerHTML =
        '<p>You do not have permission to access this page. It will close automatically after <span id="time">5</span> seconds.</p>';
    let x = 50;
    setInterval(function () {
        x--;
        if (x > 0) {
            document.getElementById("time").innerHTML = x.toString();
        } else {
            window.location.href = sessionStorage.getItem("redirectLink");
            window.close();
        }
    }, 1000);
}
